import { Modal, Box, Button, Typography, LinearProgress } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { useNavigate } from "react-router-dom";

function LinearProgressWithLabel({ value }) {
  return (
    <Box sx={{ width: "100%", textAlign: "center" }}>
      <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
        {`${Math.round(value)}%`}
      </Typography>
      <LinearProgress
        sx={{
          height: 10,
          borderRadius: 5,
          mb: 3,
        }}
        variant="determinate"
        value={value}
      />
    </Box>
  );
}

function ResistCommonModalContent({
  isOpen,
  alertMessage,
  uploadProgress,
  handleClose,
}) {
  const navigate = useNavigate();

  const defaultHandleClose = () => {
    handleClose(); // モーダルを閉じる
    navigate("/manage"); // ページ遷移
  };

  return (
    <Modal open={isOpen}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          minHeight: 250,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            mt: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            p: 2,
            borderRadius: 2,
            height: "100%",
            justifyContent: "center",
          }}
        >
          {alertMessage === "アップロード中..." ? (
            <LinearProgressWithLabel value={uploadProgress} />
          ) : alertMessage === "アップロードが完了しました" ? (
            <CheckCircleIcon sx={{ color: "#4caf50", fontSize: 40, mb: 1 }} />
          ) : (
            <CancelIcon sx={{ color: "#f44336", fontSize: 40, mb: 1 }} />
          )}
          <Typography variant="h6">{alertMessage}</Typography>
          {alertMessage !== "アップロード中..." && (
            <Button
              variant="contained"
              color="primary"
              onClick={defaultHandleClose}
              sx={{ mt: 2 }}
            >
              戻る
            </Button>
          )}
        </Box>
      </Box>
    </Modal>
  );
}

export default ResistCommonModalContent;

import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import { useState, useEffect } from "react";
import { API } from "@aws-amplify/api";
import * as queries from "../graphql/queries";
import VideoCardList from "../components/VideoCardList.js";

function MyPage({ getUserApiResult }) {
  const [userInformation, setUserInformation] = useState({});
  const [viewHistoryInformation, setViewHistoryInformation] = useState([]);
  const [loading, setLoading] = useState(true);
  const [readMore, setReadMore] = useState(true);
  const [likedVideos, setLikedVideos] = useState([]);
  const [viewHistoryVideos, setViewHistoryVideos] = useState([]);

  const [selectedTab, setSelectedTab] = useState(0);
  const [refresh, setRefresh] = useState(false);

  const extraContent = (
    <span onClick={() => setReadMore(!readMore)} className="read-more">
      ...さらに表示
    </span>
  );

  const lessContent = (
    <span onClick={() => setReadMore(!readMore)} className="read-less">
      ...閉じる
    </span>
  );

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const variables = {
          email: getUserApiResult.email,
        };
        const userInformationResponse = await API.graphql({
          query: queries.getUserInformation,
          variables: variables,
        });
        setUserInformation(
          userInformationResponse.data.getUserInformation.userData,
        );
      } catch (error) {
        console.error("Error:", error);
      }
    };

    const historyFetchData = async () => {
      try {
        const variables = {
          email: getUserApiResult.email,
        };
        const viewHistoryResponse = await API.graphql({
          query: queries.getViewHistory,
          variables: variables,
        });
        setViewHistoryInformation(
          viewHistoryResponse.data.getViewHistory.viewHistory,
        );
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
    historyFetchData();
  }, [getUserApiResult, refresh]); // fetchDataは一度だけ実行する

  useEffect(() => {
    const fetchLikedVideoInfo = async () => {
      let tempArray = [];
      const likedVideoidList = userInformation.liked_videoid_list;
      if (likedVideoidList && likedVideoidList.length > 0) {
        await API.graphql({
          query: queries.getVideoByIdList,
          variables: { idList: likedVideoidList },
        }).then((result) => {
          const videoList = result.data.getVideoByIdList.video_list;
          for (const video of videoList) {
            if (video.status === "COMPLETE") {
              tempArray.push(video);
            } else if (video.status === "DELETED") {
              tempArray.push({
                ...video,
                setting_thumbnail_url: "Thumbnail_DELETED",
              });
            }
          }
        });
      }
      setLikedVideos(tempArray);
      setLoading(false);
    };

    if (userInformation.liked_videoid_list) {
      fetchLikedVideoInfo();
    }
  }, [userInformation]); // userInformationが更新された時にfetchLikedVideoInfoを実行

  // viewHistoryInformationの変更を監視し、変更があった場合にfetchViewHistoryInfoを実行
  useEffect(() => {
    const fetchViewHistoryInfo = async () => {
      let viewHistoryArray = [];
      if (viewHistoryInformation && viewHistoryInformation.length > 0) {
        await API.graphql({
          query: queries.getVideoByIdList,
          variables: { idList: viewHistoryInformation },
        }).then((result) => {
          const videoList = result.data.getVideoByIdList.video_list;
          for (const video of videoList) {
            if (video.status === "COMPLETE") {
              viewHistoryArray.push(video);
            } else if (video.status === "DELETED") {
              viewHistoryArray.push({
                ...video,
                setting_thumbnail_url: "Thumbnail_DELETED",
              });
            }
          }
        });
      }
      setViewHistoryVideos(viewHistoryArray);
      setLoading(false);
    };

    if (viewHistoryInformation && viewHistoryInformation.length > 0) {
      fetchViewHistoryInfo();
    }
  }, [viewHistoryInformation]);

  if (loading) {
    return <div></div>;
  }

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center" // 縦方向も中央揃え
        sx={{
          width: "100%", // 幅を画面サイズに合わせる
          maxWidth: "87vw", // 横幅を画面内に収める
          overflowX: "hidden", // 横スクロールを防止
          overflowY: "hidden", // 横スクロールを防止
          boxSizing: "border-box", // レイアウト崩れ防止
        }}
      >
        <Grid item sx={{ width: "100%", boxSizing: "border-box" }}>
          <Card
            sx={{
              width: "100%",
              maxWidth: "100%", // 最大幅を設定して余白を調整
              margin: "0 auto", // 中央寄せ
              bgcolor: blueGrey[50],
              boxSizing: "border-box",
            }}
          >
            <CardContent
              sx={{
                boxSizing: "border-box",
                "&:last-child": {
                  paddingBottom: 2, // 最後の子要素へのスタイルも上書き
                },
              }}
            >
              <Box
                sx={{
                  width: "100%", // 親要素に合わせて幅を設定
                  display: "flex", // 横並びにする
                  alignItems: "center", // 垂直方向を中央揃え
                  justifyContent: "flex-start",
                  gap: 2, // アイテム間の余白を設定
                }}
              >
                <Avatar
                  alt="User Image"
                  src={userInformation.profile_picture}
                  sx={{ width: 100, height: 100 }}
                />
                <Typography
                  sx={{
                    fontSize: {
                      xs: "1.5rem", // スマホ画面用
                      md: "2.1rem", // PC用
                    },
                    wordBreak: "break-word", // 長い単語を折り返す
                  }}
                >
                  {userInformation.user_name}
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "100%", // 親要素に合わせて幅を設定
                  display: "flex", // 横並びにする
                  alignItems: "center", // 垂直方向を中央揃え
                  justifyContent: "flex-start",
                  paddingTop: 1, // 上側のPaddingを追加（必要に応じて調整）
                }}
              >
                <Typography
                  color="text.primary"
                  sx={{
                    fontSize: {
                      xs: "1.1rem", // スマホ画面用
                      md: "1.5rem", // PC用
                    },
                  }}
                >
                  プロフィール
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "100%", // 親要素に合わせて幅を設定
                  display: "flex", // 横並びにする
                  alignItems: "center", // 垂直方向を中央揃え
                  justifyContent: "flex-start",
                }}
              >
                <Typography
                  color="text.primary"
                  sx={{
                    fontSize: {
                      xs: "0.8rem", // スマホ画面用
                      md: "1.1rem", // PC用
                    },
                  }}
                >
                  {userInformation.profile_text
                    ? readMore
                      ? userInformation.profile_text.length > 100
                        ? userInformation.profile_text.substring(0, 100)
                        : userInformation.profile_text
                      : userInformation.profile_text
                    : ""}
                  {userInformation.profile_text &&
                    userInformation.profile_text.length > 100 &&
                    (readMore ? extraContent : lessContent)}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* タブ部分 */}
      <Tabs value={selectedTab} onChange={handleTabChange}>
        <Tab
          label="いいねした動画"
          sx={{ fontSize: "1.0rem", fontWeight: "bold" }}
        />
        <Tab
          label="視聴した動画"
          sx={{ fontSize: "1.0rem", fontWeight: "bold" }}
        />
      </Tabs>
      {selectedTab === 0 && (
        <div>
          {likedVideos.length === 0 ? (
            <Typography variant="h5" style={{ margin: "1rem 0" }}>
              いいねした動画はありません
            </Typography>
          ) : (
            <VideoCardList
              data={likedVideos}
              viewFlg={3}
              lastFlg=""
              showHeartButton={true}
              userEmail={userInformation.email}
              refreshLikedVideo={() => setRefresh(!refresh)}
              dataLength={likedVideos.length}
            />
          )}
        </div>
      )}
      {selectedTab === 1 && (
        <div>
          {viewHistoryVideos.length === 0 ? (
            <Typography variant="h5" style={{ margin: "1rem 0" }}>
              視聴した動画はありません
            </Typography>
          ) : (
            <VideoCardList
              data={viewHistoryVideos}
              viewFlg={4}
              lastFlg=""
              showHeartButton={false}
              userEmail={userInformation.email}
              refreshLikedVideo={() => setRefresh(!refresh)}
              dataLength={viewHistoryVideos.length}
            />
          )}
        </div>
      )}
    </>
  );
}
export default MyPage;

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

// ドコパヘッダーコンポーネント
import DocopaHeader from "./DocopaHeader.js";
import SearchForm from "./SearchForm.js";

function DocopaDrawer({ getUserApiResult }) {
  const [key, setKey] = useState(0);
  const navigate = useNavigate();
  const isMobileScreen = useMediaQuery("(max-width:600px)");

  const handleLogoClick = () => {
    // keyを切り替えることで検索条件を初期化
    key === 1 ? setKey(0) : setKey(1);
    navigate(`/top`);
  };

  return (
    <>
      <DocopaHeader
        isManagePath={false}
        cursor={true}
        handleLogoClick={handleLogoClick}
        getUserApiResult={getUserApiResult}
      />
      <div key={key}>
        {/*横幅が600px以上のみ左の検索バーを表示*/}
        {!isMobileScreen && <SearchForm />}
      </div>
    </>
  );
}
export default DocopaDrawer;

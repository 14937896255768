import React, { useState, useEffect } from "react";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";

import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";

import headerDocopaLogo from "./../img/headerDocopaLogo.ico";
import SearchForm from "./SearchForm.js";

import { useOktaAuth } from "@okta/okta-react";

/**
 * ドコパヘッダーコンポーネント共通か
 * @param isManagePath 管理者URL：true
 * @param cursor カーソル表示（ログイン画面）：false
 * @param handleLogoClick ロゴのオンクリックイベント
 * @returns ドコパヘッダーコンポーネント
 */
function DocopaHeader({
  isManagePath,
  cursor,
  handleLogoClick,
  getUserApiResult,
}) {
  const [userInformation, setUserInformation] = useState({});
  const [key, setKey] = useState(0);
  const { oktaAuth } = useOktaAuth();
  const isMobileScreen = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();

  const [searchUI, setSearchUI] = useState(false);

  // ログアウト処理
  const handleLogout = async () => {
    //課題：accessTokenをエラーが出ないようにrevokeするよう修正
    await oktaAuth.signOut({ revokeAccessToken: false });
  };

  //検索UI表示処理
  const handleSearch = () => {
    setSearchUI(!searchUI);
  };

  //マイページ遷移処理
  const handleMyPage = () => {
    closeSearchUI();
    navigate("/mypage");
  };

  const closeSearchUI = () => {
    setSearchUI(false);
  };

  // カーソルの有効化：ログイン画面不可能
  const cursorStyle = cursor ? { cursor: "pointer" } : null;

  useEffect(() => {
    try {
      setUserInformation(getUserApiResult);
    } catch (error) {
      console.error("Error:", error);
    }
  }, [getUserApiResult]);

  return (
    <AppBar position="fixed">
      <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
        <Stack
          direction="row"
          onClick={() => {
            key === 1 ? setKey(0) : setKey(1);
            closeSearchUI();
            handleLogoClick();
          }}
          style={cursorStyle}
        >
          <img alt="" src={headerDocopaLogo} width="40" />
          <Typography variant="h5" noWrap marginTop="4px" marginLeft="4px">
            Docopa
          </Typography>
          {isManagePath ? (
            <Typography variant="h5" noWrap marginTop="4px" marginLeft="4px">
              管理者
            </Typography>
          ) : null}
        </Stack>
        {isMobileScreen
          ? //モバイル版のヘッダー表示
            getUserApiResult && (
              <Stack direction="row">
                <Button color="inherit" onClick={handleSearch}>
                  <SearchIcon style={{ fontSize: 30 }} />
                </Button>
                <IconButton onClick={handleMyPage}>
                  <Avatar
                    alt="User Icon"
                    src={
                      userInformation ? userInformation.profile_picture : null
                    }
                  />
                </IconButton>
                <div key={key}>
                  <SearchForm onSearch={closeSearchUI} isOpen={searchUI} />
                </div>
              </Stack>
            )
          : //PC版のヘッダー表示
            getUserApiResult && (
              <Stack direction="row" spacing={2}>
                <IconButton onClick={handleMyPage}>
                  <Avatar
                    alt="User Icon"
                    src={
                      userInformation ? userInformation.profile_picture : null
                    }
                  />
                </IconButton>
                <Button color="inherit" onClick={handleLogout}>
                  SIGN OUT
                </Button>
              </Stack>
            )}
      </Toolbar>
    </AppBar>
  );
}

export default DocopaHeader;

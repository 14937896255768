import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  forwardRef,
} from "react";
import {
  TextField,
  Button,
  Grid,
  Box,
  Typography,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  Paper,
  CircularProgress,
  IconButton,
  TableSortLabel,
  Dialog,
  DialogContent,
  Fade,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { API } from "aws-amplify";
import { styled } from "@mui/system";
import * as queries from "../graphql/queries";

// カスタムトランジションコンポーネントの定義
const Transition = forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />;
});

// スタイル定義
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxWidth: "200px", // 必要に応じて調整
  padding: theme.spacing(1), // 垂直方向のスペーシングをテーマに基づいて調整
  borderRight: `1px solid ${theme.palette.grey[400]}`, // デフォルトのボーダーを追加
}));

const ThumbnailTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(1),
  borderRight: `1px solid ${theme.palette.grey[400]}`,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: "40px", // 行の高さを減少
}));

// 検索フォームコンポーネントをメモ化
const SearchForm = React.memo(({ form, onChange, categories }) => (
  <Box mb={4}>
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          label="ID"
          name="id"
          value={form.id}
          onChange={onChange}
          variant="outlined"
          type="number" // 数値入力に制限
          InputProps={{ inputProps: { min: 0 } }} // 正の数のみ許可
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          label="Keyword"
          name="keyword"
          value={form.keyword}
          onChange={onChange}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          label="User"
          name="user"
          value={form.user}
          onChange={onChange}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="Start Date"
          name="startDate"
          type="date"
          value={form.startDate}
          onChange={onChange}
          InputLabelProps={{ shrink: true }}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="End Date"
          name="endDate"
          type="date"
          value={form.endDate}
          onChange={onChange}
          InputLabelProps={{ shrink: true }}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          select
          label="Category"
          name="category"
          value={form.category}
          onChange={onChange}
          variant="outlined"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {categories.map((cat) => (
            <MenuItem key={cat.id} value={cat.name}>
              {cat.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <Button
          type="submit" // フォーム送信ボタンとして設定
          variant="contained"
          color="primary"
          fullWidth
        >
          Search
        </Button>
      </Grid>
    </Grid>
  </Box>
));

// 動画リストコンポーネントをメモ化
const VideoList = React.memo(
  ({ videos, orderBy, order, onRequestSort, navigate, handleImageClick }) => {
    return (
      <TableContainer component={Paper}>
        <Table sx={{ borderCollapse: "separate", borderSpacing: "0" }}>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>Actions</StyledTableCell>
              <StyledTableCell>
                <TableSortLabel
                  active={orderBy === "id"}
                  direction={orderBy === "id" ? order : "asc"}
                  onClick={() => onRequestSort("id")}
                >
                  ID
                </TableSortLabel>
              </StyledTableCell>
              <ThumbnailTableCell>Thumbnail</ThumbnailTableCell>
              <StyledTableCell>
                <TableSortLabel
                  active={orderBy === "title"}
                  direction={orderBy === "title" ? order : "asc"}
                  onClick={() => onRequestSort("title")}
                >
                  Title
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell>
                <TableSortLabel
                  active={orderBy === "user"}
                  direction={orderBy === "user" ? order : "asc"}
                  onClick={() => onRequestSort("user")}
                >
                  User
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell>
                <TableSortLabel
                  active={orderBy === "tag"}
                  direction={orderBy === "tag" ? order : "asc"}
                  onClick={() => onRequestSort("category")}
                >
                  Category
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell>
                <TableSortLabel
                  active={orderBy === "register_date"}
                  direction={orderBy === "register_date" ? order : "asc"}
                  onClick={() => onRequestSort("register_date")}
                >
                  Register Date
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell>
                <TableSortLabel
                  active={orderBy === "update_date"}
                  direction={orderBy === "update_date" ? order : "asc"}
                  onClick={() => onRequestSort("update_date")}
                >
                  Update Date
                </TableSortLabel>
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {videos.length > 0 ? (
              videos.map((video) => (
                <StyledTableRow
                  key={video.id}
                  onClick={() => navigate(`/playmovie/${video.id}`)}
                  style={{ cursor: "pointer" }}
                  hover
                >
                  <StyledTableCell>
                    <IconButton
                      color="primary"
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/manage/edit`, {
                          state: { id: video.id },
                        });
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </StyledTableCell>
                  <StyledTableCell>{video.id}</StyledTableCell>
                  <ThumbnailTableCell>
                    <img
                      src={video.setting_thumbnail_url || video.thumbnail_url}
                      alt={`${video.title} Thumbnail`}
                      style={{
                        maxHeight: "30px",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        e.stopPropagation(); // 行クリックを防止
                        handleImageClick(
                          video.setting_thumbnail_url || video.thumbnail_url,
                        );
                      }}
                    />
                  </ThumbnailTableCell>
                  <StyledTableCell>{video.title}</StyledTableCell>
                  <StyledTableCell>{video.user}</StyledTableCell>
                  <StyledTableCell>{video.tag}</StyledTableCell>
                  <StyledTableCell>
                    {new Date(video.register_date).toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell>
                    {video.update_date
                      ? new Date(video.update_date).toLocaleString()
                      : "-"}
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <StyledTableRow>
                <TableCell colSpan={8} align="center">
                  No videos found
                </TableCell>
              </StyledTableRow>
            )}
          </TableBody>

          {/* フッターを追加 */}
          <TableFooter>
            <StyledTableRow>
              <StyledTableCell colSpan={8} align="right">
                <Typography variant="subtitle1">
                  総件数: {videos.length}
                </Typography>
              </StyledTableCell>
            </StyledTableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    );
  },
);

// ソート比較関数
const compare = (a, b, property, order) => {
  let aValue = a[property];
  let bValue = b[property];

  // 日付の場合はDateオブジェクトに変換
  if (property === "register_date" || property === "update_date") {
    aValue = new Date(aValue);
    bValue = new Date(bValue);
  }

  // 文字列の場合は小文字に変換して比較
  if (typeof aValue === "string") {
    aValue = aValue.toLowerCase();
    bValue = bValue.toLowerCase();
  }

  if (aValue < bValue) {
    return order === "asc" ? -1 : 1;
  }
  if (aValue > bValue) {
    return order === "asc" ? 1 : -1;
  }
  return 0;
};

// メインコンポーネント
const Management = () => {
  // フォームの状態管理
  const [form, setForm] = useState({
    id: "", // IDを数値として扱う
    keyword: "",
    startDate: "",
    endDate: "",
    user: "",
    category: "",
  });
  const [loading, setLoading] = useState(false);
  const [videos, setVideos] = useState([]);
  const [categories, setCategories] = useState([]);

  // 初期ソート状態を降順に設定
  const [order, setOrder] = useState("desc"); // 初期順序を "desc" に設定
  const [orderBy, setOrderBy] = useState("id"); // 初期ソート対象を "id" に設定

  // モーダル用の状態を追加
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const navigate = useNavigate();

  // カテゴリデータの取得
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const tagListResponse = await API.graphql({
          query: queries.getTagList,
        });

        const sortedData = tagListResponse.data.getTagList.data.sort(
          (a, b) => a.id - b.id,
        );

        setCategories(sortedData);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  // プロパティマッピングの定義
  const propertyMapping = useMemo(
    () => ({
      id: "id",
      title: "title",
      user: "user",
      category: "tag", // 'category' を 'tag' にマッピング
      register_date: "register_date",
      update_date: "update_date",
    }),
    [],
  );

  /**
   * フォーム入力のハンドリングをuseCallbackでメモ化
   * @param {Object} e - イベントオブジェクト
   */
  const handleFormChange = useCallback((e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  }, []);

  /**
   * 検索ボタンのハンドリングをuseCallbackでメモ化
   */
  const handleSearch = useCallback(async () => {
    setLoading(true);
    try {
      // IDが入力されている場合は数値に変換
      const idValue = form.id ? parseInt(form.id, 10) : null;

      // 開始日と終了日に時間を追加
      const updateDateFrom = form.startDate
        ? new Date(`${form.startDate}T00:00:00`).toISOString()
        : null;
      const updateDateTo = form.endDate
        ? new Date(`${form.endDate}T23:59:59`).toISOString()
        : null;

      const searchVariables = {
        title: form.keyword,
        update_date_from: updateDateFrom,
        update_date_to: updateDateTo,
        ...(form.category && { tag: form.category }),
      };

      const searchResponse = await API.graphql({
        query: queries.getSearchList,
        variables: searchVariables,
      });

      const videoList = searchResponse.data.getSearchList.video_list;

      // 【暫定対処】getSearchList が id をサポートしていないため、フロントエンドで id に基づいてフィルタリング
      const filteredVideos = idValue
        ? videoList.filter((video) => video.id === idValue)
        : videoList;

      setVideos(filteredVideos);

      // ソート状態を降順にリセット
      setOrder("desc"); // ソート順を "desc" に設定
      setOrderBy("id"); // ソート対象を "id" に設定
    } catch (error) {
      console.error("Error fetching videos:", error);
    } finally {
      setLoading(false);
    }
  }, [form]);

  /**
   * ソートリクエストのハンドリングをuseCallbackでメモ化
   * @param {string} property - ソート対象のプロパティ
   */
  const handleRequestSort = useCallback(
    (property) => {
      const mappedProperty = propertyMapping[property] || property;
      const isAscending = orderBy === mappedProperty && order === "asc";
      const newOrder = isAscending ? "desc" : "asc";
      setOrder(newOrder);
      setOrderBy(mappedProperty);
    },
    [order, orderBy, propertyMapping],
  );

  // ソート済み動画のメモ化
  const sortedVideos = useMemo(() => {
    return [...videos].sort((a, b) => compare(a, b, orderBy, order));
  }, [videos, orderBy, order]);

  /**
   * 画像クリック時のハンドリング
   * @param {string} imageUrl - クリックされた画像のURL
   */
  const handleThumbnailClick = useCallback((imageUrl) => {
    setSelectedImage(imageUrl);
    setIsModalOpen(true);
  }, []);

  /**
   * モーダルを閉じるハンドリング（selectedImageのリセットはしない）
   */
  const handleCloseImageModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  /**
   * モーダルが完全に閉じた後にselectedImageをリセット
   */
  const handleImageModalExited = useCallback(() => {
    setSelectedImage("");
  }, []);

  return (
    <Box p={3}>
      {/* タイトルとRegisterボタンを配置するためのGridコンテナ */}
      <Grid container justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4" gutterBottom>
          管理者画面mock
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/manage/register")} // Registerページへの遷移
        >
          Register
        </Button>
      </Grid>

      {/* 検索フォームコンポーネントを<form>タグでラップ */}
      <form
        onSubmit={(e) => {
          e.preventDefault(); // デフォルトのフォーム送信を防止
          handleSearch(); // 検索処理を実行
        }}
      >
        <SearchForm
          form={form}
          onChange={handleFormChange}
          categories={categories}
        />
      </form>

      {/* ローディング中はCircularProgressを表示、それ以外は動画リストを表示 */}
      {loading ? (
        <Box display="flex" justifyContent="center" mt={4}>
          <CircularProgress />
        </Box>
      ) : (
        <VideoList
          videos={sortedVideos}
          orderBy={orderBy}
          order={order}
          onRequestSort={handleRequestSort}
          navigate={navigate}
          handleImageClick={handleThumbnailClick} // 画像クリックハンドラを渡す
        />
      )}

      {/* 画像拡大表示用のモーダル */}
      <Dialog
        open={isModalOpen}
        onClose={handleCloseImageModal}
        TransitionComponent={Transition} // カスタムトランジションを適用
        onExited={handleImageModalExited} // トランジション終了時にselectedImageをリセット
        maxWidth="md"
        fullWidth
      >
        <DialogContent>
          <img
            src={selectedImage}
            alt="Enlarged Thumbnail"
            style={{ width: "100%", height: "auto" }}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Management;

import * as React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ja from "dayjs/locale/ja";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// AppSync呼び出し用
import { API } from "@aws-amplify/api";
import * as queries from "../graphql/queries";

import {
  Button,
  Box,
  Typography,
  Drawer,
  List,
  ListItemButton,
  FormGroup,
  FormControlLabel,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  ListItem,
  ListItemText,
  FormControl,
  Radio,
  RadioGroup,
} from "@mui/material";

const drawerWidth = 260;

function convertTimezone(dateString) {
  const date = new Date(dateString);
  date.setHours(date.getHours() + 9);
  const formattedDate = date.toISOString(); // ISO 8601 形式の文字列に変換
  return formattedDate;
}

function ConfirmationDialogRaw(props) {
  const { onClose, value: valueProp, data: dataProp, open, ...other } = props;
  const [value, setValue] = useState(valueProp);
  const tags = dataProp.map((item) => item.name);

  useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(value);
  };

  const handleChange = (event) => {
    const { value: tag, checked } = event.target;
    let updatedValue;

    if (checked) {
      updatedValue = [...value, tag];
    } else {
      updatedValue = value.filter((item) => item !== tag);
    }

    setValue(updatedValue);
  };

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
      maxWidth="xs"
      open={open}
      {...other}
    >
      <DialogTitle>動画カテゴリ選択</DialogTitle>
      <DialogContent dividers>
        {tags.map((tag, index) => (
          <FormGroup key={index}>
            <FormControlLabel
              control={
                <Checkbox
                  value={tag}
                  checked={value.includes(tag)}
                  onChange={handleChange}
                />
              }
              label={tag}
            />
          </FormGroup>
        ))}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          Cancel
        </Button>
        <Button onClick={handleOk}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
}

function SearchForm({ onSearch, isOpen }) {
  const isMobileScreen = useMediaQuery("(max-width:600px)");
  const [open, setOpen] = useState(false);
  // タイトル入力値
  const [title, setTitle] = useState("");
  // タグ選択値
  const [tagValue, setTagValue] = useState([]);
  // 更新日From値
  const [dateFrom, setDateFrom] = useState();
  // 更新日to値
  const [dateTo, setDateTo] = useState();
  // ソート
  const [sort, setSort] = useState("1");
  // getSearchListリクエスト
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  // 更新日From値（表示更新用）
  const [dateFromDisplay, setDateFromDisplay] = useState(null);
  // 更新日to値（表示更新用）
  const [dateToDisplay, setDateToDisplay] = useState(null);
  // accordion開閉制御
  const [isAccordionOpen1, setIsAccordionOpen1] = useState(false);
  const [isAccordionOpen2, setIsAccordionOpen2] = useState(false);
  const [isAccordionOpen3, setIsAccordionOpen3] = useState(false);

  // リクエスト情報
  const requestData = {
    title: title,
    tag: tagValue,
    update_date_from: dateFrom,
    update_date_to: dateTo,
    sort: sort,
  };

  const handleTagClickOpen = () => {
    setOpen(true);
  };

  const handleTagClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setTagValue(newValue);
    }
  };

  /**検索開始日設定*/
  const checkDateFrom = (date) => {
    if (!checkValidDate(date)) {
      setDateFrom(null);
      setDateFromDisplay(null);
    } else {
      setDateFrom(`${convertTimezone(date.$d).slice(0, 10)} 00:00:00`);
      setDateFromDisplay(date.$d);
    }
  };

  /**検索終了日設定*/
  const checkDateTo = (date) => {
    if (!checkValidDate(date)) {
      setDateTo(null);
      setDateToDisplay(null);
    } else {
      setDateTo(`${convertTimezone(date.$d).slice(0, 10)} 23:59:59`);
      setDateToDisplay(date.$d);
    }
  };

  // 日付フォーマットチェック
  const checkValidDate = (date) => {
    console.log("check");
    return date === null || !isNaN(new Date(date).getTime());
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const tagListResponse = await API.graphql({
          query: queries.getTagList,
        });

        // データをidでソート
        const sortedData = tagListResponse.data.getTagList.data.sort(
          (a, b) => a.id - b.id,
        );

        setData(sortedData);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        //モバイル版検索バーの表示設定
        "@media (max-width: 600px)": {
          display: isOpen ? "flex" : "none",
          width: "0px",
          "& .MuiDrawer-paper": {
            width: "100%",
            height: "auto",
            marginTop: "56px",
            borderBottom: "1px solid gray",
          },
        },
        //PC版検索バーの表示設定
        "@media (min-width: 600px)": {
          display: "flex",
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            height: { xs: "calc(100% - 56px)", sm: "calc(100% - 64px)" },
            marginTop: { xs: "56px", sm: "64px" },
          },
        },
        "& ::-webkit-scrollbar": {
          display: "none",
        },
        "& :hover": {
          "::-webkit-scrollbar": {
            display: "inline",
          },
        },
      }}
      variant="permanent"
      anchor="left"
    >
      {/*検索エリア*/}
      <Box
        sx={{
          pl: 1,
          display: "flex",
          alignItems: "flex-end",
          mt: "10px",
          "@media (max-width: 600px)": {
            pb: "20px",
            mt: "0px",
            justifyContent: "left", // 水平方向中央寄せ
            alignItems: "center",
            bgcolor: "#ffffff",
          },
        }}
      >
        {!isMobileScreen && <SearchIcon />}
        <TextField
          label="キーワードを入力"
          value={title}
          variant="standard"
          sx={{
            "@media (max-width: 600px)": {
              ml: "10px",
              width: "90%",
            },
            //bgcolor: "white",
          }}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
        />
      </Box>
      {/*タグエリア*/}
      <Accordion
        sx={{
          mt: "30px",
          "@media (max-width: 600px)": {
            mt: "0px",
          },
        }}
        expanded={isAccordionOpen1}
        onChange={
          //モバイル版の場合開けるアコーディオンは１つとする
          isMobileScreen
            ? () => {
                setIsAccordionOpen1(!isAccordionOpen1);
                setIsAccordionOpen2(false);
                setIsAccordionOpen3(false);
              }
            : () => setIsAccordionOpen1(!isAccordionOpen1)
        }
        disableGutters
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            background: "#90caf9",
          }}
        >
          <Typography>カテゴリ絞り込み</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: "flex",
            justifyContent: "center", // 水平方向中央寄せ
          }}
        >
          <Box
            sx={{
              width: "100%",
              maxWidth: 200,
              bgcolor: "background.paper",
            }}
          >
            <List component="div" role="group">
              <ListItem
                button
                divider
                aria-haspopup="true"
                aria-controls="tag-menu"
                onClick={handleTagClickOpen}
              >
                <ListItemText>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography>
                      {tagValue.length === 1
                        ? tagValue[0]
                        : tagValue.length > 1
                          ? `${tagValue.length} 件のカテゴリが選択`
                          : "選択されていません"}
                    </Typography>
                  </Box>
                </ListItemText>
              </ListItem>
            </List>
            <ConfirmationDialogRaw
              id="tagDialog"
              keepMounted
              open={open}
              onClose={handleTagClose}
              value={tagValue}
              data={data}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
      {/*日付エリア*/}
      <Accordion
        expanded={isAccordionOpen2}
        onChange={
          isMobileScreen
            ? () => {
                setIsAccordionOpen1(false);
                setIsAccordionOpen2(!isAccordionOpen2);
                setIsAccordionOpen3(false);
              }
            : () => setIsAccordionOpen2(!isAccordionOpen2)
        }
        disableGutters
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            background: "#42a5f5",
          }}
        >
          日付絞り込み
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: "flex",
            justifyContent: "center", // 水平方向中央寄せ
          }}
        >
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 2 }}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={ja}
              >
                <DatePicker
                  label="検索開始日"
                  value={dateFromDisplay}
                  disableFuture={true}
                  format="YYYY/MM/DD"
                  onChange={checkDateFrom}
                />
              </LocalizationProvider>
            </ListItemButton>
            <ListItemButton sx={{ pl: 2 }}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={ja}
              >
                <DatePicker
                  label="検索終了日"
                  value={dateToDisplay}
                  disableFuture={true}
                  format="YYYY/MM/DD"
                  onChange={checkDateTo}
                />
              </LocalizationProvider>
            </ListItemButton>
          </List>
        </AccordionDetails>
      </Accordion>

      {/*ソートエリア*/}
      <Accordion
        expanded={isAccordionOpen3}
        onChange={
          isMobileScreen
            ? () => {
                setIsAccordionOpen1(false);
                setIsAccordionOpen2(false);
                setIsAccordionOpen3(!isAccordionOpen3);
              }
            : () => setIsAccordionOpen3(!isAccordionOpen3)
        }
        disableGutters
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            background: "#2196f3",
          }}
        >
          動画表示順
        </AccordionSummary>
        <AccordionDetails>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={sort}
              name="radio-buttons-group"
              sx={{ pl: 2 }}
              onChange={(e) => {
                setSort(e.target.value);
              }}
            >
              <FormControlLabel
                control={<Radio />}
                value="1"
                label="更新日が新しい順"
              />
              <FormControlLabel
                control={<Radio />}
                value="2"
                label="更新日が古い順"
              />
              <FormControlLabel
                control={<Radio />}
                value="3"
                label="再生数が多い順"
              />
              <FormControlLabel
                control={<Radio />}
                value="4"
                label="再生数が少ない順"
              />
              <FormControlLabel
                control={<Radio />}
                value="5"
                label="いいねが多い順"
              />
              <FormControlLabel
                control={<Radio />}
                value="6"
                label="いいねが少ない順"
              />
            </RadioGroup>
          </FormControl>
        </AccordionDetails>
      </Accordion>
      <Box
        style={{
          position: "sticky",
          bottom: 0,
          zIndex: 1,
        }}
        textAlign="center"
        sx={{
          "@media (max-width: 600px)": {
            bgcolor: "#00bfff",
          },
        }}
      >
        <Button
          variant="contained"
          sx={{
            width: "100px",
            mx: "auto",
            my: "30px",
            "@media (max-width: 600px)": {
              my: "10px",
            },
          }}
          onClick={() => {
            if (onSearch) {
              onSearch();
            }
            navigate(`/top`, { state: requestData });
          }}
        >
          検索
        </Button>
      </Box>
    </Drawer>
  );
}
export default SearchForm;

import { useState } from "react";
import axios from "axios";

import { checkFileName } from "../variables/ManageRelated.js";

import CommonForm from "../components/CommonForm.js";
import ResistModalContent from "../components/ResistModalContent";
import { API } from "aws-amplify";
import * as mutations from "../graphql/mutations";

function Register({ getUserApiResult }) {
  const [alertMessage, setAlertMessage] = useState("アップロード中...");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0); // 進捗率のステート

  const onSubmit = async (inputData) => {
    // ポップアップ表示、他の操作不可にする
    setIsModalOpen(true);
    setAlertMessage("アップロード中...");

    // Step1. DBに登録
    const data = {
      videoTitle: inputData.title,
      videoTag: inputData.tag,
      videoSubTag: inputData.subTags.split(",").map((tag) => tag.trim()), // AppSync側は配列なのでそのまま渡す
      description: inputData.descriptions,
      videoFile: checkFileName(inputData.video[0].name),
    };

    if (inputData.thumbnail.length > 0) {
      data.thumbnailFile = checkFileName(inputData.thumbnail[0].name);
    }

    let videoUrl = "";
    let thumbnailUrl = "";

    try {
      // AppSyncのmutationをAPI.graphqlで呼び出す
      const response = await API.graphql({
        query: mutations.registerVideo,
        variables: {
          registerVideoItem: data, // 送信するデータを変数として渡す
        },
      });

      const result = response.data.registerVideo;

      if (result.resultCode === 2000) {
        // 成功時の処理
        videoUrl = result.videoUrl;
        thumbnailUrl = result.thumbnailUrl;
      } else {
        console.error("動画登録APIに失敗しました");
      }
    } catch (error) {
      console.error("AppSync APIとの接続に失敗しました", error);
    }
    const [baseUrl, queryParams] = videoUrl.split("?");
    const fullUrl = `${baseUrl}?${queryParams}`;

    // Step3. S3にアップロード 動画
    try {
      const videoHeaders = {
        "Content-Type": "video/mp4",
      };

      const videoUploadResponse = await axios.put(fullUrl, inputData.video[0], {
        headers: videoHeaders,
        timeout: 900000, // 15分（900,000ミリ秒）でタイムアウト
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100,
          );
          setUploadProgress(progress); // 進捗率を更新
        },
      });

      if (videoUploadResponse.status === 200) {
        console.log("動画のアップロードに成功しました");
      }
    } catch (error) {
      if (error.code === "ECONNABORTED") {
        console.error("アップロードがタイムアウトしました");
        setAlertMessage("アップロードがタイムアウトしました"); // モーダルにタイムアウトメッセージを表示
        return;
      } else {
        console.error(`動画のアップロードに失敗しました, error=${error}`);
      }
    }

    // Step4. S3にアップロード サムネイル
    if (inputData.thumbnail.length > 0) {
      try {
        const thumbnailHeaders = {
          "Content-Type": "image/*",
        };

        const thumbnailUploadResponse = await axios.put(
          thumbnailUrl,
          inputData.thumbnail[0],
          { headers: thumbnailHeaders },
        );

        if (thumbnailUploadResponse.status === 200) {
          console.log("サムネイル画像のアップロードを完了しました");
        }
      } catch (error) {
        console.error(
          `サムネイル画像のアップロードに失敗しました, error=${error}`,
        );
      }
    }

    // 動画登録完了のポップアップメッセージを設定
    setAlertMessage("アップロードが完了しました");
  };

  // 初期値設定
  const initVal = {
    title: "",
    tag: "",
    subtags: [],
    description: [],
    setting_thumbnail_url: "",
    video_url: "",
  };

  return (
    <>
      <CommonForm
        onSubmit={onSubmit}
        initVal={initVal}
        state="regist"
        getUserApiResult={getUserApiResult}
      />
      <ResistModalContent
        isOpen={isModalOpen}
        alertMessage={alertMessage}
        uploadProgress={uploadProgress} // 進捗率を渡す
        handleClose={() => setIsModalOpen(false)}
      />
    </>
  );
}

export default Register;
